/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Pracovní doba"}>
        <Column className="--menu css-11mx124 css-42e4bw pb--08 pt--08" menu={true} name={"gdc9bz0r9jk"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_ove=000000x15__s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_ove=000000x15__s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_ove=000000x15__s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_ove=000000x15__s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_ove=000000x15__s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_ove=000000x15__s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_ove=000000x15__s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_ove=000000x15__s=3000x_.png);
    }
  
background-position: 50% 50%;
    `}>
          
          <Menu style={{"maxWidth":1958}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box subtitle-box--left fs--72" style={{"maxWidth":563}} content={"<span style=\"color: rgb(255, 255, 255);\">Pracovní doba</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor1" use={"page"} href={"/"} target={""} content={"Hlavní stránka"}>
                </Button>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn1 btn-box--sbtn2" use={"page"} href={"/dodavatele"} target={""} content={"Dodavatelé"}>
                </Button>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn1 btn-box--sbtn2" use={"page"} href={"/akce"} target={""} content={"Akce"}>
                </Button>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn1 btn-box--sbtn2" use={"page"} href={"/galerie"} target={""} content={"Galerie"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--10 pl--25 pr--25 pt--10" anim={""} name={"prazdna_sekcee"} animS={"4"} border={""} layout={"l1"}>
          
          <ColumnWrap className="column__flex --shape2 --center el--1 --full mt--10 pb--10" anim={""} animS={""} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"maxWidth":1337,"paddingBottom":null}}>
              
              <Title className="title-box title-box--left ff--2 mt--06" style={{"maxWidth":1406,"marginTop":null}} content={"<span style=\"font-weight: bold;\">Pondělí až pátek</span>&nbsp;&nbsp;<span style=\"font-style: italic;\">8:00 - 12:00</span><br><span style=\"font-weight: bold;\"><span style=\"color: rgb(255, 255, 255);\"> Pondělí až pátek</span>&nbsp;&nbsp;</span><span style=\"font-style: italic;\">13:00 - 17:00<br></span><br>Případně dle telefonické domluvy.<br>"}>
              </Title>

              <Title className="title-box title-box--left ff--2 mt--06" style={{"maxWidth":1406,"marginTop":null}} content={"<span style=\"font-weight: bold;\">Víkendy</span> - <span style=\"font-style: italic;\">zavřeno</span><br>"}>
              </Title>

              <Title className="title-box title-box--center ff--2" style={{"maxWidth":572}} content={"V případě naší nepřítomnosti můžete kdykoliv volat na telefonní číslo<br>776 458 444<br>733 267 272<br><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}